<template>
  <div class="app-wrapper">
    <template v-if="user">
      <navbar class="main-navbar"></navbar>
      <div style="display: flex;flex-direction: row;">
        <sidebar :menus="menus"></sidebar>
        <el-scrollbar class="main-container" viewClass="main-container-scrollbar-view"
                      wrap-class="main-container-scrollbar-wrapper">
          <div class="app-main">
            <transition name="fade-transform" mode="out-in">
              <keep-alive :include="cachedViews">
                <router-view :key="key" v-on:closeView="closeView"></router-view>
              </keep-alive>
            </transition>
          </div>
          <page-footer class="main-footer"></page-footer>
        </el-scrollbar>
      </div>
    </template>
  </div>
</template>

<script>
import Navbar from "@/layouts/components/Navbar"
import Sidebar from "@/layouts/components/Sidebar"
// import TagsView from "@/layouts/components/TagsView"
import PageFooter from "@/components/PageFooter"
import {getMenus} from "@/api/auth";

export default {
  name: "SecurityLayout",
  data() {
    return {
      menus: [],
    }
  },
  components: {
    Navbar,
    Sidebar,
    // TagsView,
    PageFooter,
  },
  computed: {
    key() {
      return this.$route.path
    },
    user() {
      return this.$store.state.auth.user
    },
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
  },
  created() {
    let that = this
    //  验证是否登录
    let loadingInstance = that.$loading()
    that.$store.dispatch('auth/autoLogin').then(() => {
      loadingInstance.close()
      that.getMenus()
    }).catch((err) => {
      console.log(err)
      loadingInstance.close()
      that.$router.push({path: '/admin/login'})
    })
  },
  mounted() {

  },
  methods: {
    getMenus() {
      let that = this
      getMenus().then(res => {
        that.menus = res
      })
    },
    closeView(to) {
      if (to) {
        this.$router.push(to)
      }
      // this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
      //   if (to) {
      //     this.$router.push(to)
      //   } else {
      //     this.$refs.tagsViewRef.toLastView(visitedViews, this.$route)
      //   }
      // })
    },
  },
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;

  .main-container {
    flex: 1;
    height: calc(100vh - 48px);
    background-color: #f0f2f5;

    & /deep/ .main-container-scrollbar-wrapper {
      overflow-x: hidden !important;
    }

    & /deep/ .main-container-scrollbar-view {
      height: calc(100vh - 48px);
      display: flex;
      flex-direction: column;

      .main-navbar {
        flex: 0 0 auto;
      }

      .app-main {
        background-color: #FAFAFA;
        flex: auto;
      }

      .main-footer {
        flex: 0 0 auto;
        background-color: #141414;
        color: white;
      }
    }
  }

  .full-loading {
    width: 100vw;
    height: 100vh;
  }
}
</style>
