const modulesFiles = require.context('./', true, /.js$/)
let temp = [];
const modules = modulesFiles.keys().filter(function (file) {
  return file !== "./routes.js"
}).reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath)
  temp = temp.concat(value.default)
  return temp
}, {})

export default modules