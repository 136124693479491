<template>
  <div class="app-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "BasicLayout",
  data() {
    return {}
  },
  created() {

  },
  mounted() {

  },
  methods: {},
}
</script>

<style lang="scss">
.app-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.full-loading {
  margin: auto;
}

</style>
