export default [
  {
    path: '/admin/rent-set-developer',
    name: 'DeveloperConfigures',
    component: () => import('./DeveloperConfigures.vue'),
    meta: {title: '划转租金配置',parent: '系统设置'},
  },
  {
    path: '/admin/rent-set-developer-info/:id',
    name: 'DeveloperConfigureInfo',
    component: () => import('./DeveloperConfigureInfo.vue'),
    meta: {title: '开发商划转租金配置详情',parent: '系统设置'},
  },
  {
    path: '/admin/rent-set-distributor',
    name: 'DistributorConfigures',
    component: () => import('./DistributorConfigures.vue'),
    meta: {title: '经销商划转租金配置',parent: '系统设置'},
  },
  {
    path: '/admin/rent-set-distributor-info/:id',
    name: 'DistributorConfigureInfo',
    component: () => import('./DistributorConfigureInfo.vue'),
    meta: {title: '经销商划转租金配置详情',parent: '系统设置'},
  },
]
