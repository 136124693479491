export default [
  {
    path: '/admin/acceptance',
    name: 'acceptanceList',
    component: () => import('./List.vue'),
    meta: {title: '施工验收', parent: '业务管理'},
  },
  {
    path: '/admin/acceptance/info/:id',
    name: 'acceptanceInfo',
    component: () => import('./Info.vue'),
    meta: {title: '施工详情', parent: '业务管理'},
  },
]