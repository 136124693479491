export default [
  {
    path: '/admin/contract',
    name: 'contractList',
    component: () => import('./List.vue'),
    meta: {title: '合同管理', parent: '合同管理'},
  },
  {
    path: '/admin/contract/edit/:id',
    name: 'contractEdit',
    component: () => import('./Edit.vue'),
    meta: {title: '发起合同', parent: '合同管理'},
  },
  {
    path: '/admin/contract/info/:id',
    name: 'contractInfo',
    component: () => import('./Info.vue'),
    meta: {title: '合同详情', parent: '合同管理'},
  },
  {
    path: '/admin/contract/select',
    name: 'contractEdit',
    component: () => import('./Select.vue'),
    meta: {title: '发起合同', parent: '合同管理'},
  },
]