export default [
  {
    path: '/admin/survey',
    name: 'Survey',
    component: () => import('./Survey.vue'),
    meta: {title: '踏勘工单', parent: '业务管理'},
  },
  {
    path: '/admin/survey/info/:id',
    name: 'SurveyInfo',
    component: () => import('./SurveyInfo.vue'),
    meta: {title: '踏勘详情', parent: '业务管理'},
  },
]