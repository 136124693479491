export default [
  {
    path: '/admin/contract-contract',
    name: 'Contracts',
    component: () => import('./Contracts.vue'),
    meta: {title: '合同管理',parent: '合同管理'},
  },
  {
    path: '/admin/electronic-contracts',
    name: 'ElectronicContracts',
    component: () => import('./ElectronicContracts.vue'),
    meta: {title: '电子签管理',parent: '合同管理'},
  },
  // {
  //   path: '/admin/contract-template',
  //   name: 'ContractTemplate',
  //   component: () => import('./ContractTemplate.vue'),
  //   meta: {title: '合同模板'},
  // },
  // {
  //   path: '/admin/contract-seal',
  //   name: 'ContractSeal',
  //   component: () => import('./ContractSeal.vue'),
  //   meta: {title: '合同印章'},
  // }
]