export default [
  {
    path: '/admin/design',
    name: 'designList',
    component: () => import('./List.vue'),
    meta: {title: '设计管理', parent: '业务管理'},
  },
  {
    path: '/admin/design/info/:id',
    name: 'designInfo',
    component: () => import('./Info.vue'),
    meta: {title: '设计详情', parent: '业务管理'},
  },
  {
    path: '/admin/design/edit/:id',
    name: 'designEdit',
    component: () => import('./Edit.vue'),
    meta: {title: '设计上传', parent: '业务管理'},
  },
]