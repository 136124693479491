export default [
  {
    path: '/admin/index',
    name: 'Index',
    component: () => import('./Index.vue'),
    meta: {title: '首页', affix: true}
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: () => import('./Dashboard.vue'),
    meta: {title: '欢迎登录'}
  },
]
