export const APP_NAME = '中南光电户用光伏开发管理平台'

export const BASE_URI = '/api'

export const COMPANY_NAME = '合肥中南光电有限公司'

// 开发
// export const PIC_URL = 'http://192.168.1.202:9996/api'
// 测试
// export const PIC_URL = 'https://chinaland.sinoartisan.com/api'
// 正式
export const PIC_URL = 'https://soft.sunergyworks.com/api'

export const PROVINCE_CODES = [
  {id:11102, name:'北京供电公司',province:'北京市'},
  {id:12101, name:'天津市电力公司',province:'天津市'},
  {id:13102, name:'河北省电力公司',province:'河北省'},
  {id:13103, name:'冀北电力公司',province:'河北省'},
  {id:14101, name:'山西省电力公司',province:'山西省'},
  {id:15101, name:'内蒙古东部电力有限公司',province:'内蒙古自治区'},
  {id:21102, name:'辽宁省电力有限公司',province:'辽宁省'},
  {id:22101, name:'吉林省电力有限公司',province:'吉林省'},
  {id:23101, name:'黑龙江省电力有限公司',province:'黑龙江省'},
  {id:31102, name:'上海市电力公司',province:'上海市'},
  {id:32101, name:'江苏省电力公司',province:'江苏省'},
  {id:33101, name:'浙江省电力公司',province:'浙江省'},
  {id:34101, name:'安徽省电力公司',province:'安徽省'},
  {id:35101, name:'福建省电力有限公司',province:'福建省'},
  {id:36101, name:'江西省电力公司',province:'江西省'},
  {id:37101, name:'山东省电力集团公司',province:'山东省'},
  {id:41101, name:'河南省电力公司',province:'河南省'},
  {id:42102, name:'湖北省电力公司',province:'湖北省'},
  {id:43101, name:'湖南省电力公司',province:'湖南省'},
  {id:50101, name:'重庆市电力公司',province:'重庆市'},
  {id:51101, name:'四川省电力公司',province:'四川省'},
  {id:54101, name:'西藏区电力有限公司',province:'西藏自治区'},
  {id:61102, name:'陕西省电力公司',province:'陕西省'},
  {id:62101, name:'甘肃省电力公司',province:'甘肃省'},
  {id:63101, name:'青海省电力公司',province:'青海省'},
  {id:64101, name:'宁夏区电力公司',province:'宁夏回族自治区'},
  {id:65101, name:'新疆区电力公司',province:'新疆维吾尔自治区'},
]

export const FARMER_TYPES = [
  {label:  '农户', value: 0},
  {label:  '公共屋顶', value: 1},
]

export const USER_STATE = [
  {label:  '已实名', value: 1},
  {label:  '未实名', value: 0},
]

export const TASK_STATE_NAME = {
  'WAIT': '待踏勘接单',
  'SURVEYED': '踏勘中',
  'EXAMINE': '建站审核',
  'SUCCESS': '审核成功',
  'WORKING': '施工中',
  'OVER': '验收通过',
  'UPLOAD': '已提并网',
  'WAITING_SIGN': '待签约',
  'FAIL': '建站审核拒绝',
  'CLOSE': '封单',
  'WAIT_RECEIVE': '待验收',
}

// 新状态
// 用户管理
export const USER_MANAGEMENT_STATUS = {
  'INIT': '用户管理',
  'TK': '踏勘阶段',
  'HT': '合同管理',
  'SJ': '设计管理',
  'WL': '项目物料',
  'SG': '施工验收',
  'BW': '并网管理',
  'JS': '结算管理',
  'SUCCESS': '已完成',
}

// 技术踏勘状态
export const TECHNICAL_SURVEY_STATUS = {
  'WAIT': '待踏勘接单',
  'SURVEYED': '踏勘中',
  'EXAMINE': '建站审核',
  'SUCCESS': '审核成功',
  'FAIL': '建站审核失败',
  'CLOSE': '封单',
}

// 施工验收状态
export const CONSTRUCTION_ACCEPTANCE_STATUS = {
  'INIT': '待派单',
  'WAIT_GET': '待施工接单',
  'WORKING': '施工中',
  'EXAMINE': '经销商审核',
  'FAIL': '审核不通过',
  'SUCCESS': '审核通过',
  'EXAMINE_TWO': '开发商审核',
}

// 设计管理状态
export const DESIGN_MANAGEMENT_STATUS = {
  'INIT': '设计上传',
  'EXAMINE': '设计审核',
  'FAIL': '审核不通过',
  'SUCCESS': '审核通过',
}

// 项目物料状态
export const PROJECT_MATERIAL_STATUS = {
  'INIT': '待发货',
  'ING': '运输中',
  'OVER': '已收货',
}

// 并网管理状态
export const GRID_CONNECTION_STATUS = {
  'INIT': '待并网',
  'EXAMINE': '并网审核',
  'FAIL': '并网不通过',
  'SUCCESS': '已并网',
}

// 意向消纳状态
export const INTENTION_CONSUMPTION_STATE = {
  'ALL': '全额上网',
  'SELF': '自发自用',
  'SURPLUS': '余电上网',
}

// 结算
export const SETTLEMENT_STATUS = {
  'INIT': '待结算',
  'SUCCESS': '已结算',
}

// 合同管理状态
export const CONTRACT_MANAGEMENT_STATUS = {
  'PART': '签署中',
  'ALL': '签署完成',
  'REJECT': '拒签',
  'CANCEL': '撤回',
  'DEADLINE': '流签',
}

// 合同类型
export const CONTRACT_TYPE = [
  {label: '屋顶租赁', value: '02'},
  {label: '全款销售', value: '03'},
  {label: '个人贷款', value: '01'},
]
