<template>
  <div :class="[{'is-collapse': isCollapse}]" class="sidebar-container">
    <!--<div class="sidebar-header">-->
    <!--<img alt="" class="sidebar-logo" src="@/assets/logo.png">-->
    <!--<div class="sidebar-title">{{ app_name }}</div>-->
    <!--</div>-->
    <el-scrollbar class="sidebar-menu" wrap-class="sidebar-menu-scrollbar-wrapper">
      <el-menu
              :collapse="!isCollapse" :router="true"
              :unique-opened="true"
              :background-color="'#141414'"
              :text-color="'#FFFFFFA5'"
              :active-text-color="'#FFFFFF'"
              class="sidebar-el-menu"
              :default-active="defaultActive">
        <template v-for="(item, index) in menus">
          <template v-if="item.child && item.child.length > 0">
            <el-submenu :index="index + ''" :key="index">
              <div slot="title">
                <i :class="item.icon" class="menu-icon"></i>
                <span slot="title">{{ item.name }}</span>
              </div>
              <el-menu-item-group>
                <template v-for="(menu, menuIndex) in item.child">
                  <el-menu-item :index="menu.url" :key="menuIndex" class="my-menu-active" style="color:#adadad;">{{
                    menu.name }}
                  </el-menu-item>
                </template>
              </el-menu-item-group>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item :index="item.url" :key="index">
              <i :class="item.icon" class="menu-icon"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </el-scrollbar>
    <div class="sidebar-footer">
      <div class="sidebar-collapse-btn">
        <i :class="isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="changeCollapse"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {APP_NAME} from "@/utils/config"

export default {
  name: "Sidebar",
  props: {
    menus: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      app_name: APP_NAME,
      isCollapse: true,
      defaultActive: null,
    }
  },
  watch: {
    '$route'(to) {
      this.defaultActive = to.path
    }
  },
  created() {

  },
  mounted() {
    this.defaultActive = this.$route.path
  },
  methods: {
    changeCollapse() {
      this.isCollapse = !this.isCollapse
    }
  },
}
</script>

<style lang="scss">
.sidebar-container {
  width: 48px;
  height: calc(100vh - 48px);
  transition: width .28s linear;
  background-color: #141414;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  z-index: 9;

  &.is-collapse {
    width: 208px;

    .sidebar-menu-scrollbar-wrapper {
      width: 208px;
    }
  }

  .sidebar-header {
    height: 48px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;

    .sidebar-logo {
      width: 48px;
      height: 48px;
      padding: 10px;
      object-fit: fill;
    }

    .sidebar-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 48px;
      color: #FFFFFF;
    }
  }

  .sidebar-menu {
    flex: 1;
    padding: 8px 0;
    width: 100%;
    border-right: none;

    ::v-deep .sidebar-menu-scrollbar-wrapper {
      overflow-x: hidden !important;
    }

    .sidebar-menu-scrollbar-wrapper::-webkit-scrollbar {
      width: 0 !important
    }

    .sidebar-el-menu {
      border-right: unset;

      .el-menu-item.is-active {
        background-color: $--color-primary !important;
      }
    }

    .el-menu--collapse {
      width: 48px;
    }

    .menu-icon {
      width: 1em;
      text-align: center;
      margin-right: 5px;
      color: white !important;
    }
  }

  .sidebar-footer {
    height: 48px;

    .sidebar-collapse-btn {
      width: 48px;
      line-height: 48px;
      font-size: 26px;
      text-align: center;
      color: #FFFFFFA5;
    }
  }
}

.sidebar-container .sidebar-menu .sidebar-el-menu .el-menu-item.is-active {
  background-color: rgba(24, 144, 255, .2) !important;
  border-right: 3px solid #1890FF !important;
  color: #1890FF !important;
}

.el-submenu .el-menu-item {
  height: 35px !important;
  line-height: 35px !important;
  padding: 0 45px;
  min-width: 171px !important;
}

.el-submenu__title, .el-menu-item {
  height: 45px !important;
  line-height: 45px !important;
}

.el-menu-item-group__title {
  padding: 0 0 0 20px !important;
}
</style>

<style>
.sidebar-el-menu.el-menu.el-menu--collapse {
  width: 48px;
}

.sidebar-el-menu.el-menu > .el-menu-item, .sidebar-el-menu.el-menu > .el-submenu .el-submenu__title {
  padding-left: 17px !important;
  text-align: left;
  color: #fff !important;
}

.sidebar-el-menu.el-menu.el-menu--collapse .el-menu-item {
  padding: 0 !important;
  text-align: center;
}

.sidebar-el-menu.el-menu.el-menu--collapse .el-menu-item .el-tooltip,
.sidebar-el-menu.el-menu.el-menu--collapse .el-submenu .el-submenu__title {
  padding: 0 !important;
  text-align: center;
}
</style>
