import request from "@/utils/request"

export function login(username, password) {
  return request.post(`/login?username=${username}&password=${password}`)
}

export function getUser() {
  return request.get('/user/info')
}

export function getMenus() {
  return request.get('/menu/admin/my')
}

export function resetPwd(old_password, password) {
  return request.post('/resetPassword', {
    old_password: old_password,
    password: password
  })
}
