<template>
  <footer class="page-footer">
    <div class="footer-links">
<!--      <a href="https://beian.miit.gov.cn/" target="_blank" title="CompanyName">xICP备xxxxxxxx号</a>-->
    </div>
    <div class="footer-copyright"><!--© {{ COMPANY_NAME }} 版权所有--> </div>
  </footer>
</template>

<script>
import {COMPANY_NAME} from "@/utils/config"

export default {
  name: "PageFooter",
  data() {
    return {
      COMPANY_NAME:COMPANY_NAME,
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.page-footer {
	width:100%;
  padding: 10px 0;
  text-align: center;
  background-color: #F4F7FC ;

  .footer-links {
    margin-bottom: 8px;

    a {
      color: rgba(0, 0, 0, .45);
      transition: all .3s;

      &:not(:last-child) {
        margin-right: 40px;
      }

      &:hover {
        color: rgba(0, 0, 0, .85);
      }
    }
  }

  .footer-copyright {
    color: white;
    font-size: 14px;
  }
}
</style>
